.footer-container{
    position: relative;
}

.footer-container>hr{
    border: 1px solid var(--lightgray);
}

.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}

.social-links{
   display: flex;
   gap: 4rem; 
}

.social-links>img{
    width: 3rem;
    height: 3rem;
    cursor: pointer;
}

.logo-f>img{
    width: 15rem;
}


.blur-f-l{
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: rgb(164, 42, 42);
}

.blur-f-r{
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: rgb(237, 109, 4);
}

.download-from{
   display: flex;
   margin-bottom: 40px;
   gap: 4rem; 
   cursor: pointer;
   align-items: center;
   justify-content: center;
}

.background{
    position: absolute;
      left: 0px;
      top: 15px;
      z-index: -1;
      width: 100%;
      height: 100%;
      -webkit-filter: blur(5px);
      filter: blur(3px);
}

@media screen and (max-width: 768px){
    .download-from{
        flex-direction: column;
    }
    .download-from>img{
        width: 10rem;
    }
}